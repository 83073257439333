<template>
  <base-modal
    :showing="visible"
    size="max-w-6xl"
    @opened="onOpened"
    @close="onClosed"
  >
    <base-card
      title="Daftar Barang"
      description="Data barang dari order yang telah dibuat"
      :with-style="false"
    >
      <div class="space-y-4 border-t pt-5">
        <div class="flex items-center justify-end gap-4">
          <ToggleButton
            :labels="{
              checked: 'QR Kurang',
              unchecked: 'QR Tambah',
            }"
            :width="96"
            color="#dc2626"
            v-model="qrScan.isDecrementQty"
          />
          <base-input
            :shadow="false"
            placeholder="Cari kode"
            v-model="search"
          />
        </div>

        <datatable
          :columns="[
            {
              id: 'check',
              name: '',
              theadClass: 'pr-0',
            },
            {
              id: 'qrcode',
              name: '',
              theadClass: 'pr-0',
            },
            {
              code: 'code',
              name: 'Kode Barang',
            },
            {
              code: 'name',
              name: 'Nama Barang',
            },
            {
              code: 'total',
              name: 'Total Produk',
            },
            {
              code: 'qty',
              name: 'Jumlah',
            },
            {
              code: 'action',
              name: '',
              theadClass: 'text-right',
            },
          ]"
        >
          <template #tbody="{ classes }">
            <template v-if="!products.data.length">
              <td colspan="7" :class="classes.td">
                <div
                  class="flex flex-col items-center space-y-2 py-4 text-center"
                >
                  <Icon
                    icon="heroicons:check-circle"
                    class="h-6 w-6 text-gray-400"
                  />
                  <p class="text-xs font-normal text-gray-400">
                    Tidak ada data barang<br />yang belum dikemas
                  </p>
                </div>
              </td>
            </template>
            <template v-else>
              <template v-for="(product, index) in products.data">
                <tr :key="`product-${index}`" :class="classes.tr">
                  <td :class="[classes.td, 'pr-0']">
                    <base-checkbox
                      :input-checked="!!selectedProducts[index].qty"
                      disabled
                      :with-label="false"
                    />
                  </td>
                  <td :class="[classes.td, 'pr-0']">
                    <Icon
                      v-if="checkProductHasQr(product)"
                      icon="heroicons:qr-code"
                      class="h-4 w-4"
                    />
                  </td>
                  <td :class="classes.td">
                    <p class="font-medium text-gray-900">
                      {{ product.attributes.product_code }}
                      <span v-if="product.type === 'order-detail-bonuses'"
                        >(BONUS)</span
                      >
                    </p>
                  </td>
                  <td :class="classes.td">
                    {{ product.attributes.product_name }}
                  </td>
                  <td :class="classes.td">
                    {{ product.attributes.product_qty }}
                  </td>
                  <td :class="classes.td">
                    {{ selectedProducts[index].qty ?? 0 }}
                  </td>
                  <td :class="classes.td">
                    <div class="flex justify-end">
                      <button @click="onToggleBatchProductVisible(index)">
                        <Icon
                          :icon="
                            batchProductVisible.includes(index)
                              ? 'heroicons:chevron-down'
                              : 'heroicons:chevron-right'
                          "
                          class="h-4 w-4"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
                <template v-if="batchProductVisible.includes(index)">
                  <tr
                    v-for="(batch, batchIndex) in selectedProducts[index]
                      .batches"
                    :key="`product-${index}-batch-${batchIndex}`"
                    :class="[classes.tr, 'bg-gray-50']"
                  >
                    <td :class="classes.td">
                      <base-checkbox
                        :input-checked="
                          !!selectedProducts[index].batches[batchIndex].qty &&
                          selectedProducts[index].batches[batchIndex].qty > 0
                        "
                        disabled
                        :with-label="false"
                      />
                    </td>
                    <td :class="classes.td"></td>
                    <td :class="classes.td">
                      <base-button
                        v-if="checkProductHasQr(product)"
                        size="sm"
                        :color="
                          checkBatchScanStatus(index, batchIndex)
                            ? 'warning'
                            : 'white'
                        "
                        @click="onToggleBatchScanQr(index, batchIndex)"
                      >
                        {{
                          checkBatchScanStatus(index, batchIndex)
                            ? 'Batal Scan QR'
                            : 'Scan QR'
                        }}
                      </base-button>
                    </td>
                    <td :class="classes.td">{{ batch.code }}</td>
                    <td :class="classes.td">{{ batch.stock }}</td>
                    <td :class="classes.td">
                      <input
                        type="text"
                        class="block border-0 bg-transparent p-0 text-sm placeholder-gray-300 focus:ring-0"
                        placeholder="Masukkan jumlah"
                        :value="selectedProducts[index].batches[batchIndex].qty"
                        :disabled="checkProductHasQr(product) || checkBatchScanStatus(index, batchIndex)"
                        @change="(e) => onChangeBatchQty(e, index, batchIndex)"
                      />
                    </td>
                    <td :class="classes.td"></td>
                  </tr>
                </template>
              </template>
            </template>
          </template>
        </datatable>

        <div class="flex justify-end gap-2">
          <base-button @click="onConfirm">Konfirmasi</base-button>
          <base-button color="white" @click="$emit('close')"
            >Kembali</base-button
          >
        </div>
      </div>
    </base-card>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
  components: {
    BaseModal,
    ToggleButton,
    BaseCheckbox,
  },
  props: {
    products: Object,
    packets: Array,
    visible: Boolean,
    productBoxes: Array,
    productQrCodes: Array
  },
  emits: ['close', 'confirmed'],
  data() {
    return {
      batchProductVisible: [],
      search: null,
      selectedProducts: [],
      qrScan: {
        isDecrementQty: false,
        productIndex: null,
        batchIndex: null,
      },
    };
  },
  methods: {
    calculateSelectedProductQty(index) {
      this.selectedProducts[index].qty = this.selectedProducts[index].batches
        .filter((batch) => !!batch.qty && Number(batch.qty) > 0)
        .reduce((total, batch) => (total += Number(batch.qty)), 0);
    },
    checkProductHasQr(product) {
      const productDetail = this.getSingleIncluded(
        this.products,
        product.relationships.product.data.id
      );

      if (
        productDetail.attributes.qrcode &&
        productDetail.attributes.is_qrcode_active
      ) {
        return true;
      }

      const hasBoxes = this.productBoxes.some(
        (box) => box.product.uuid === productDetail.id
      )
      const hasQrCodes = this.productQrCodes.some(
        (qr) => qr.product.uuid === productDetail.id
      )

      return hasBoxes || hasQrCodes;
    },
    checkBatchScanStatus(index, batchIndex) {
      if (this.qrScan.batchIndex === null) {
        return false;
      }

      if (this.qrScan.productIndex !== index) {
        return false;
      }

      return this.qrScan.batchIndex === batchIndex;
    },
    getProductStockBatches(productId, productType) {
      return this.products.meta.stock_batches.filter(
        (batch) =>
          batch.product.uuid === productId && batch.product_type == productType
      );
    },
    getStockBatchUsedQty(batchId, productType) {
      return this.packets
        .filter((packet) =>
          packet.products.some((product) =>
            product.batches.some(
              (batch) =>
                batch.id === batchId && batch.product_type === productType
            )
          )
        )
        .reduce((total, packet) => {
          return (
            total +
            packet.products.reduce((total, product) => {
              return (
                total +
                product.batches
                  .filter(
                    (batch) =>
                      batch.id === batchId && batch.product_type === productType
                  )
                  .reduce((total, batch) => total + batch.qty, 0)
              );
            }, 0)
          );
        }, 0);
    },
    getQrObject(qrCode) {
      const product = this.products.data[this.qrScan.productIndex];
      const productDetail = this.getSingleIncluded(
        this.products,
        product.relationships.product.data.id
      );

      if (
        productDetail.attributes.qrcode === qrCode &&
        productDetail.attributes.is_qrcode_active
      ) {
        return {
          detail: productDetail,
          amount: 1
        };
      }

      const productBox = this.productBoxes.find(
        (box) => box.product.uuid === productDetail.id && box.qrcode === qrCode
      );

      if (productBox) {
        return {
          detail: productBox,
          amount: productBox.product_count
        };
      }

      const productQrCode = this.productQrCodes.find(
        (qrcode) => qrcode.product.uuid === productDetail.id && qrcode.qrcode === qrCode
      );

      if (productQrCode) {
        const productQrCodeUsedInCurrentPacket = this.selectedProducts.some(product => product.batches.some(batch => batch.qrCodes.includes(productQrCode.qrcode)))
        const productQrCodeUsedInOtherPacket = this.packets.some(packet => packet.products.some(product => product.batches.some(batch => batch.qrCodes.includes(productQrCode.qrcode))))

        if (!productQrCodeUsedInCurrentPacket && !productQrCodeUsedInOtherPacket) {
          return {
            type: 'product-qr',
            detail: productQrCode,
            amount: 1
          }
        }
      }

      return null;
    },
    onToggleBatchProductVisible(index) {
      if (this.batchProductVisible.includes(index)) {
        this.batchProductVisible.splice(
          this.batchProductVisible.indexOf(index),
          1
        );
      } else {
        this.batchProductVisible.push(index);
      }
    },
    onChangeBatchQty(e, index, batchIndex) {
      const qty = Number(e.target.value);
      const { stock, qty: batchQty } =
        this.selectedProducts[index].batches[batchIndex];
      const { qty: inputQty } = this.selectedProducts[index];
      const { product_qty: productQty } = this.products.data[index].attributes;

      const reamainder = productQty - (inputQty - batchQty);

      if (isNaN(qty) || qty > stock || qty < 0 || qty > reamainder) {
        e.target.value = this.selectedProducts[index].batches[batchIndex].qty;
      } else {
        this.selectedProducts[index].batches[batchIndex].qty = Number(
          e.target.value
        );

        this.calculateSelectedProductQty(index);
      }
    },
    onConfirm() {
      const selectedProducts = this.selectedProducts.filter(
        (product) => !!product.qty && Number(product.qty) > 0
      );
      const packetProducts = selectedProducts.map((selectedProduct) => {
        const productDetail = this.products.data.find(
          (productDetail) =>
            productDetail.id === selectedProduct.orderDetailOrBonusId
        );

        return {
          orderDetailOrBonusId: selectedProduct.orderDetailOrBonusId,
          type: selectedProduct.type,
          productId: selectedProduct.productId,
          qty: Math.min(
            Number(selectedProduct.qty),
            Number(productDetail.attributes.product_qty)
          ),
          batches: selectedProduct.batches
            .filter((batch) => !!batch.qty && Number(batch.qty) > 0)
            .map((batch) => ({
              ...batch,
              qty: Number(batch.qty),
            })),
        };
      });

      this.$emit('confirmed', packetProducts);
    },
    onOpened() {
      this.batchProductVisible = [];
      this.qrScan.productIndex = null;
      this.qrScan.batchIndex = null;
      this.selectedProducts = this.products.data.map((product) => ({
        orderDetailOrBonusId: product.id,
        type: product.type,
        productId: product.attributes.product_id,
        qty: null,
        batches: this.getProductStockBatches(
          product.attributes.product_id,
          product.type === 'order-details' ? 'reguler' : 'free'
        ).map((batch) => {
          return {
            id: batch.id,
            code: batch.code,
            product_type: batch.product_type,
            stock:
              batch.stock_qty -
              this.getStockBatchUsedQty(
                batch.id,
                product.type === 'order-details' ? 'reguler' : 'free'
              ) -
              batch.stock_used_qty,
            qty: null,
            qrCodes: []
          };
        }),
      }));

      document.addEventListener('barcode-scan', this.onQrScanned);
    },
    onClosed() {
      document.removeEventListener('barcode-scan', this.onQrScanned);

      this.$emit('close');
    },
    onToggleBatchScanQr(index, batchIndex) {
      if (this.checkBatchScanStatus(index, batchIndex)) {
        this.qrScan.productIndex = null;
        this.qrScan.batchIndex = null;
      } else {
        this.qrScan.productIndex = index;
        this.qrScan.batchIndex = batchIndex;
      }
    },
    onQrScanned(e) {
      if (this.qrScan.batchIndex === null) {
        return;
      }

      const { text: qrCode } = e.detail;

      const qr = this.getQrObject(qrCode);

      if (qr !== null) {
        const { amount, type, detail } = qr

        const product = this.selectedProducts[this.qrScan.productIndex];
        const productDetail = this.products.data[this.qrScan.productIndex];
        const selectedBatch =
          this.selectedProducts[this.qrScan.productIndex].batches[
            this.qrScan.batchIndex
          ];

          if (type === 'product-qr') {
            this.selectedProducts[this.qrScan.productIndex].batches[this.qrScan.batchIndex].qrCodes.push(detail.qrcode)
          }

        const isDecrementQty = type !== 'product-qr' && this.qrScan.isDecrementQty

        if (isDecrementQty) {
          const qty = Math.min(amount, selectedBatch.qty);

          if (qty === 0) {
            this.selectedProducts[this.qrScan.productIndex].batches[
              this.qrScan.batchIndex
            ].qty = null;
          } else {
            this.selectedProducts[this.qrScan.productIndex].batches[
              this.qrScan.batchIndex
            ].qty -= qty;
          }
        } else {
          if (selectedBatch.qty < productDetail.attributes.product_qty) {
            const qty = Math.min(
              amount,
              selectedBatch.stock,
              productDetail.attributes.product_qty - product.qty
            );

            this.selectedProducts[this.qrScan.productIndex].batches[
              this.qrScan.batchIndex
            ].qty += qty;
          }
        }

        this.calculateSelectedProductQty(this.qrScan.productIndex);
      }
    },
  },
};
</script>
